import"./global.css";
import"./cdk-overlay.css";
import"./layout.scss";
import { userProfileService } from "@spx-workflow/utility/user-profile";
import { changeVariableStyle, loadFont } from "./utility";
const supportFonts = ['Roboto', 'Noto+Sans'];
loadFont(supportFonts);
userProfileService.userConfiguration.subscribe(config => {
    let font = 'Roboto';
    if (config.language === 'ja-JP') {
        font = 'Noto Sans';
    }
    changeVariableStyle('--bs-body-font-family', font)
})
//Anythingexportedfromthisfileisimportablebyotherin-browsermodules.
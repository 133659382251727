// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: "Roboto", sans-serif;
  margin: 0;
}

p, span, h1, h2, h3, h4, h5, h6, a {
  white-space: pre-wrap;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,SAAS;AACX;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":["body {\n  font-family: \"Roboto\", sans-serif;\n  margin: 0;\n}\n\np, span, h1, h2, h3, h4, h5, h6, a {\n  white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

export const loadFont = (fonts: string[]) => {
    fonts.forEach(font => {
        const linkElFont = document.createElement('link');
        linkElFont.rel = 'stylesheet';
        linkElFont.href = `https://fonts.googleapis.com/css2?family=${font}&display=swap`;
        document.head.append(linkElFont);
    });
}
export const changeVariableStyle = (variableKey: string, variable: any) => {
    const root = document.querySelector(':root') as any;
    root.style.setProperty(variableKey, variable);
}